<template>
  <div>
    <vue-phone-number-input
      class='block w-full'
      v-model='phoneNumber'
      :translations='translations'
      :valid-color='eventMainThemeColor'
      :default-country-code='defaultCountryCode'
      @update='updatePhoneNumberInput' />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'EditFormFieldPhoneNumber',
  components: {
    VuePhoneNumberInput
  },
  props: [
    'value',
    'metaData'
  ],
  data () {
    return {
      phoneNumber: '',
      isValidPhoneNumber: false,
    }
  },
  watch: {
    phoneNumberValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:phoneNumberValue', newVal)
        }
      }, 
      immediate: true,
      deep: true,
    },
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.phoneNumber = newVal.phoneNumber
          this.isValidPhoneNumber = newVal.isValidPhoneNumber
        }
      }, 
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor'
    ]),
    translations () {
      return {
        phoneNumberLabel: this.placeholder,
      }
    },
    defaultCountryCode () {
      return this.profile.country ? this.profile.country : 'KR'
    },
    phoneNumberValue () {
      return {
        phoneNumber: this.phoneNumber,
        isValidPhoneNumber: this.isValidPhoneNumber,
      }
    },
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  },
  methods: {
    updatePhoneNumberInput (data) {
      this.phoneNumber = data.formatInternational
      this.isValidPhoneNumber = data.isValid
    },
  },
}
</script>
